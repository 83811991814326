/**
 * qt20160119 与bm工具集成，所有文件上传页面必须在init()中调用此方法
 */
var secagent = null;
function sec_on_load(userid,level){
	if(navigator.appName != "Microsoft Internet Explorer"){
		alert("您所使用的浏览器不支持bm工具证书，无法上传或下载文件");
		return false;
	}
	try{
		secagent = new ActiveXObject("sensinfoctrl.SecAgent");
		secagent.login(userid,level);//level设置为100，则可按照邮件规则
	}catch(e){
		
	}
	if(secagent == null){
		alert("不能创建bm工具对象，请登录科工邮件系统并下载安装bm工具！");
		return false;
	}
}


/**
 * qt20160120 获取文件的bm信息，没有bm或获取失败则标为0
 */
function getFileSecretLevel(){
	var fileLevel = "0";
	if(secagent != null){
		var file = document.getElementById('fileData');
		if(file!=undefined){
			var filename = file.value;
			if(filename.length>0){
				var sensinfo = secagent.GetSensInfo(filename);
//				var len = secagent.GetFileLength(filename);
				var list=sensinfo.split(";");
				if(list.length>0){
					var secret = list[0].split("=");
					if(fileLevel.length>0){
						fileLevel = secret[1];
					}
				}
			}			
		}
	}
	return fileLevel;
}